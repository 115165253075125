$base-icon-path: "../../common/assets/icons";

.MuiButton-contained {
  i {
    margin-right: 10px;
  }
  &:not(.Mui-disabled) {
    i {
      background-color: #fff;
    }
  }
  &.Mui-disabled {
    i {
      background-color: rgba(0, 0, 0, 0.26);
    }
  }
}

@mixin icon($path, $size: 24px, $color: #000) {
  display: inline-flex;
  width: $size;
  height: $size;
  -webkit-mask: url("#{$base-icon-path}/#{$path}") no-repeat center;
  -webkit-mask-size: contain;
  background-color: $color;
}

.ic-continue {
  @include icon("continue-ic.png", 20px, #000);
}

.ic-default-socket {
  @include icon("sockets/default-socket-ic.png", 30px, #000);
}

.ic-time {
  @include icon("time-ic.png", 20px, #000);
}

.ic-banknote {
  @include icon("banknote-ic.png", 20px, #000);
}

.ic-charge {
  @include icon("charge-ic.png", 20px, #000);
}

.ic-card-hand {
  @include icon("card-hand-ic.png", 20px, #000);
}

.ic-charger {
  @include icon("charger-ic.png", 20px, #000);
}

.ic-stop {
  @include icon("stop-ic.png", 20px, #000);
}

.ic-mail {
  @include icon("mail-ic.png", 20px, #000);
}

.ic-refresh {
  @include icon("refresh-ic.png", 20px, #000);
}

.ic-cancel {
  @include icon("cancel-ic.png", 20px, #000);
}
